/* AnalyticsPage.css */

.analytics-container {
    margin: 20px;
  }
  
  .analytics-container h1 {
    color: #4B0082; /* Purple color */
  }
  
  .analytics-container p {
    font-size: 18px;
  }
  