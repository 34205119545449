.homepage {
    font-family: 'Roboto', sans-serif;
    color: #333;
  }

  .main-content {
    padding-top: 60px; /* Adjust based on your navbar's actual height */
    padding-bottom: 60px;
    margin-right: 10px;
}
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0.5rem 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed; /* If you want the navbar to be fixed at the top */
    top: 0;
    width: 100%; /* Ensure it spans the full width */
    z-index: 1000; /* Ensure it stays on top of other content */
  }
  
  .navbar-logo {
    height: 40px;
    margin-right: 10px;
  }
  
  .brand {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 40px;
    margin-right: 0.5rem;
  }
  
  .brand-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #3F51B5;
  }
  
  .icons .search-icon,
  .icons .notification-icon {
    font-size: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
  }
  
  .dashboard-metrics {
    background-color: #f4f4f4;
    padding: 1rem;
    text-align: center;
  }
  
  .main-actions {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;
  }
  
  .scrollable-cards {
    overflow-x: auto;
    white-space: nowrap;
    padding: 1rem 0;
  }
  
  .coupons,
  .bills {
    display: inline-block;
    margin-right: 1rem;
  }
  
  .bottom-nav {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .bottom-nav .nav-item {
    padding: 0.5rem;
    text-align: center;
  }
  
  .bottom-nav .nav-item.active {
    border-top: 2px solid #3F51B5;
  }
  
  .fab {
    position: fixed;
    bottom: 5rem; /* Adjust based on bottom nav height */
    right: 1rem;
    background-color: #FFC107;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 2rem;
    color: white;
    cursor: pointer;
  }
  
  .fab:hover {
    background-color: #ffb300; /* A slightly darker shade for hover state */
  }
  
  .dashboard-metrics .metric-card {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0.5rem;
    padding: 1rem;
  }
  
  .dashboard-metrics .metric-card .metric-value {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .dashboard-metrics .metric-card .metric-label {
    font-size: 0.9rem;
    color: #777;
  }
  
 /* ...existing CSS... */

.scrollable-cards .card .bills {
    display: inline-block;
    background-color: #FFFFFF;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
    font-size: 0.9rem;
  }
  
  .card img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .card .card-content {
    padding: 0.5rem;
  }
  
  .action-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    font-size: 1rem;
    cursor: pointer;
  }
  
  .action-button:hover {
    background-color: #43A047;
  }
  
  /* Footer section */
  .footer {
    background-color: #FAFAFA;
    padding: 1rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .footer .legal-links,
  .footer .social-icons {
    color: #9E9E9E;
    margin: 0.5rem;
    font-size: 0.8rem;
    text-decoration: none;
  }
  
  .footer .social-icons img {
    height: 20px;
    margin: 0 0.2rem;
  }

  :root {
    --primary-color: #2A9DF4; /* Example blue primary color */
    --secondary-color: #FFFFFF; /* White secondary color */
    --accent-color: #FFA500; /* Example orange accent color */
  }
  
  
  .navbar,
  .bottom-nav {
    background-color: var(--primary-color);
  }
  
  .brand-name,
  .bottom-nav .nav-item.active,
  .action-button {
    color: var(--secondary-color);
  }
  
  .fab {
    background-color: var(--accent-color);
  }

  /* Add these styles to your existing CSS file */

.bills {
  display: inline-block;
  background-color: #FFFFFF;
  margin: 0.5rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left; /* Align text to the left */
  font-size: 0.9rem;
  transition: transform 0.3s ease-in-out; /* Add a smooth transition effect */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.bills:hover {
  transform: translateY(-5px); /* Move the card up slightly on hover */
}

.bills .bill-id {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.bills .retailer-name {
  color: #555; /* Darken text color for retailer name */
}

.bills .total-amount {
  color: #777; /* Slightly lighter text color for total amount */
}


  
  /* Responsive design */
  @media (max-width: 768px) {
    .navbar, .footer {
      padding: 0.5rem;
    }
  
    .brand-name, .dashboard-metrics .metric-card .metric-label, .dashboard-metrics .metric-card .metric-value {
      font-size: 1rem;
    }
  
    .action-button {
      padding: 0.8rem;
    }
  
    .fab {
      bottom: 4rem; /* Adjust for mobile */
    }
  }
  
  /* Optional: animations for loading */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .scrollable-cards .card, .dashboard-metrics .metric-card, .action-button {
    animation: fadeIn 0.3s ease-in;
  }
  

  