/* This CSS applies to both RegistrationPage.css and LoginPage.css */
.registration-container, .login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5; /* Light background for a clean look */
}

.registration-form, .login-form {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.registration-form h2, .login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.registration-form label, .login-form label {
  display: block;
  margin-bottom: 5px;
}

.registration-form input, .login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.registration-form button, .login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-options {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

/* Common Styles */
.container {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
min-height: 100vh;
padding: 20px;
background-color: #f0f4f8; /* Soft background color */
}

.form {
width: 100%;
max-width: 340px; /* Optimal for mobile screens */
background-color: #ffffff;
border-radius: 10px;
box-shadow: 0 8px 16px rgba(0,0,0,0.1);
padding: 25px;
}

.form-header {
margin-bottom: 30px;
text-align: center;
}

.form-header img {
width: 120px; /* Adjust based on your logo's aspect ratio */
margin-bottom: 10px;
}

.form h2 {
margin: 0;
color: #333;
font-size: 22px;
}

.form label {
display: block;
margin-bottom: 8px;
color: #666;
}

.form input {
width: 100%;
padding: 12px;
margin-bottom: 20px;
border-radius: 5px;
border: 1px solid #ccc;
background-color: #fafafa; /* Slightly off-white background */
}

.form button {
width: 100%;
padding: 12px;
background-color: #0056b3; /* Primary button color */
color: white;
border: none;
border-radius: 5px;
cursor: pointer;
font-weight: 600;
}

.form button:hover {
background-color: #004494; /* Button hover state */
}

.options {
text-align: center;
margin-top: 20px;
}

.options a {
color: #0056b3;
text-decoration: none;
}

.options a:hover {
text-decoration: underline;
}

/* OTP Verification Specific Styles */
.otp-input {
text-align: center;
font-size: 18px;
}

.verify-btn {
background-color: #28a745; /* Success color for verification */
}

.verify-btn:hover {
background-color: #218838;
}
