/* AccountPage.css */
.account-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.account-options {
  width: 100%;
  max-width: 500px; /* Adjust based on your design preference */
}

.account-option {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  background-color: var(--primary-color); /* Assuming you've defined this in your root or main CSS file */
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.account-option.logout {
  background-color: var(--accent-color); /* Differentiate the logout button visually */
}

.account-option:hover {
  background-color: var(--secondary-color); /* Assuming you've defined this */
}
