/* ProfilePage.css */
.profile-page {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .profile-page h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .profile-details {
    margin-bottom: 10px;
    color: #555;
  }
  
  /* Adjust styling as needed */
  