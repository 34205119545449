.create-coupon-form {
  max-width: 600px; /* Slightly wider form */
  margin: 20px auto; /* More vertical space */
  padding: 20px; /* Internal spacing */
  background-color: #f9f9f9; /* Light background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow */
}

.form-group {
  padding-bottom: 20px; /* Increased spacing */
  padding-top: 10px;
}

.form-label {
  font-weight: 600; /* Make labels slightly bolder */
  color: #333; /* Darker color for better readability */
}

.form-input,
.form-select,
.form-textarea {
  padding: 10px; /* More padding for better touch targets */
  border: 1px solid #ccc; /* Lighter border */
  border-radius: 4px; /* Consistent border radius */
  background-color: #fff; /* Ensure background is white */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.form-input:focus,
.form-select:focus,
.form-textarea:focus {
  border-color: #007bff; /* Highlight focus with a primary color */
  box-shadow: 0 0 0 2px rgba(0,123,255,.25); /* Add a subtle glow */
}

.form-textarea {
  height: 150px; /* Increase height for more text */
}

.form-submit-button {
  display: block; /* Ensure button is block level */
  width: 100%; /* Full width */
  padding: 12px; /* More padding */
  background-color: #007bff; /* Primary button color */
  color: #ffffff; /* Text color */
  font-size: 16px; /* Larger font size */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Cursor */
  transition: background-color 0.2s; /* Smooth transition */
}

.form-submit-button:hover,
.form-submit-button:focus {
  background-color: #0056b3; /* Darker shade on hover/focus */
}

/* Additional button for adding terms */
.add-term-button {
  margin-top: 10px; /* Space from the last term */
  background-color: #28a745; /* Success color */
  color: #ffffff; /* White text */
  padding: 8px 12px; /* Padding */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Font size */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.2s; /* Smooth transition */
}

.add-term-button:hover,
.add-term-button:focus {
  background-color: #218838; /* Darker on hover/focus */
}

/* Styling for remove term buttons */
.remove-term-button {
  background-color: #dc3545; /* Danger color */
  color: #ffffff; /* White text */
  padding: 5px 8px; /* Smaller padding */
  margin-top: 5px; /* Space from textarea */
  border-radius: 4px; /* Rounded corners */
  font-size: 12px; /* Smaller font size */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.2s; /* Smooth transition */
}

.remove-term-button:hover,
.remove-term-button:focus {
  background-color: #c82333; /* Darker on hover/focus */
}

@media (max-width: 768px) {
  .create-coupon-form {
    padding: 15px; /* Less padding on smaller screens */
  }

  .form-submit-button,
  .add-term-button,
  .remove-term-button {
    font-size: 14px; /* Smaller buttons on small screens */
  }
}
