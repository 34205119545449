/* TermsPage.css */

.terms-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .terms-page h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .terms-page h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .terms-page p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .terms-page ul {
    margin-left: 20px;
  }
  
  .terms-page ul li {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .terms-page button {
    background-color: var(--primary-color); /* Adjust based on your color scheme */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .terms-page button:hover {
    background-color: var(--primary-color-dark); /* Adjust based on your color scheme */
  }
  
  