/* ViewCoupons.css Enhanced for Vibrancy */
.coupons-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.coupon-card {
  background: linear-gradient(145deg, #fdfbfb, #ebedee);
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.coupon-card:hover {
  transform: scale(1.03);
  box-shadow: 5px 5px 20px rgba(0,0,0,0.25);
}

.coupon-name {
  color: #007bff; /* Vibrant blue */
  font-weight: bold;
  font-size: 1.5rem;
  margin: 15px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.coupon-description {
  color: #555;
  font-size: 1rem;
  margin: 0 15px 15px;
  line-height: 1.5;
}

.coupon-expiry {
  font-size: 0.9rem;
  color: #ff6347; /* Tomato for urgency */
  text-align: right;
  margin: 0 15px 15px;
}

/* Additional styles for visual enhancements */
.coupon-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  opacity: 0.1; /* Adjust opacity for a subtle background image */
  border-radius: 12px;
}

/* Optional: Add icons or images specific to discount type */
.discount-icon {
  width: 50px;
  height: 50px;
  background-size: contain;
  position: absolute;
  top: 10px;
  right: 10px;
}

