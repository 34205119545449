.create-bill-form {
  max-width: 500px;
  margin: 2rem auto;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.create-bill-form .form-group {
  margin-bottom: 1rem;
}

.create-bill-form .form-label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: bold;
}

.create-bill-form .form-input,
.create-bill-form .form-select,
.create-bill-form .form-textarea,
.create-bill-form .item-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.create-bill-form .form-input:focus,
.create-bill-form .form-select:focus,
.create-bill-form .form-textarea:focus,
.create-bill-form .item-input:focus {
  outline: none;
  border-color: #3F51B5;
}

.create-bill-form .form-submit-button {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #3F51B5;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-bill-form .form-submit-button:hover {
  background-color: #303F9F;
}

.create-bill-form .item-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-bill-form .item-input {
  flex: 1;
  margin-right: 0.5rem; /* Spacing between inputs */
}

.create-bill-form .remove-item {
  background-color: #f44336;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-bill-form .remove-item:hover {
  background-color: #d32f2f;
}

.create-bill-form .add-item-button {
  background-color: #2196F3;
  color: white;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem; /* Spacing from last item to button */
}

.create-bill-form .add-item-button:hover {
  background-color: #1976D2;
}

.create-bill-form .total-amount {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: right;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .create-bill-form {
    margin: 1rem;
  }
  
  .create-bill-form .item-group {
    flex-direction: column;
  }

  .create-bill-form .item-input {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .create-bill-form .remove-item {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .create-bill-form .add-item-button {
    width: 100%;
  }
}

/* Animations and transitions */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.create-bill-form .form-group, 
.create-bill-form .item-group, 
.create-bill-form .form-submit-button {
  animation: fadeIn 0.3s ease-in;
}
