.coupon-details-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.coupon-details-container {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.coupon-info, .attach-user-form, .attached-users {
    margin-bottom: 20px;
}

.attach-user-form input {
    margin-right: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.attach-user-form button, .attached-users button {
    padding: 8px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
}

.attached-users ul {
    list-style: none;
    padding: 0;
}

.attached-users li {
    margin-bottom: 10px;
}

.attached-users button {
    margin-left: 10px;
}

/* Add responsive design and other styling as needed */
