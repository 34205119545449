.bill-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 20px auto;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bill-header {
  background-color: #4B0082;
  color: white;
  text-align: center;
  padding: 10px 0;
}

.bill-info {
  padding: 15px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
}

.bill-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.bill-table th,
.bill-table td {
  padding: 10px;
  padding-left: 20px;
  border-bottom: 1px solid #ddd;
}

.bill-table th:first-child,
.bill-table td:first-child {
  padding-left: 20px;
}

.bill-total {
  padding: 15px;
  text-align: right;
}

.bill-total strong {
  font-size: 18px;
  color: #4B0082;
}

